import React from "react";
import loadable from "@loadable/component";
// import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
// import CodeBlock from "../components/atoms/code-block";
const CodeBlock = loadable(() => import("../components/atoms/code-block"));

function DesignTypography() {
  return (
    <div className="design__content">
      <SEO title="Design System - Typography" />
      <h2>Typography</h2>
      <p className="design__text p2">
        Buffy has two typefaces, Cooper and Inter. Cooper Black is used mainly
        for headlines and &quot;big&quot; text, while Inter is used for body
        copy or smaller headlines. The Inter font is represented in Regular,
        Semi Bold and Bold.
      </p>

      <div className="design__stories">
        <div className="design__story-section">
          <h5>Large</h5>
          <DesignStory
            title="h1"
            type="type"
            props={{ className: "big large type-sp-m" }}>
            big
          </DesignStory>
          <DesignStory
            title="h1"
            type="type"
            props={{ className: "large type-sp-s" }}>
            header 1
          </DesignStory>
          <DesignStory
            title="h2"
            type="type"
            props={{ className: "large type-sp-s" }}>
            header 2
          </DesignStory>
          <DesignStory
            title="h3"
            type="type"
            props={{ className: "large type-sp-s" }}>
            header 3
          </DesignStory>
          <DesignStory
            title="h4"
            type="type"
            props={{ className: "large type-sp-s" }}>
            header 4
          </DesignStory>
          <DesignStory
            title="h5"
            type="type"
            props={{ className: "large type-sp-s" }}>
            header 5
          </DesignStory>
          <DesignStory
            title="h5"
            type="type"
            props={{ className: "shead large type-sp-s" }}>
            small headline
          </DesignStory>
          <DesignStory
            title="h5"
            type="type"
            props={{ className: "xshead large type-sp-s" }}>
            xsmall headline
          </DesignStory>
          <DesignStory
            title="h5"
            type="type"
            props={{ className: "xxshead large type-sp-s" }}>
            xxsmall headline
          </DesignStory>
          <DesignStory
            title="h6"
            type="type"
            props={{ className: "large type-sp-xs" }}>
            header 6
          </DesignStory>
          <DesignStory
            title="p"
            type="type"
            props={{ className: "p1 large type-sp-s" }}>
            Paragraph 1
          </DesignStory>
          <DesignStory
            title="p"
            type="type"
            props={{ className: "p2 large type-sp-s" }}>
            Paragraph 2
          </DesignStory>
          <DesignStory
            title="p"
            type="type"
            props={{ className: "p3 large type-sp-xs" }}>
            Paragraph 3
          </DesignStory>
          <DesignStory
            title="p"
            type="type"
            props={{ className: "p4 large type-sp-xs" }}>
            Paragraph 4
          </DesignStory>
          <DesignStory
            title="span"
            type="type"
            props={{ className: "microtext large" }}>
            microtext
          </DesignStory>
          <DesignStory
            title="span"
            type="type"
            props={{ className: "labeltext large" }}>
            labeltext
          </DesignStory>
          <h5>Medium</h5>
          <DesignStory
            title="h1"
            type="type"
            props={{ className: "big medium type-sp-s" }}>
            big
          </DesignStory>
          <DesignStory
            title="h1"
            type="type"
            props={{ className: "medium type-sp-s" }}>
            header 1
          </DesignStory>
          <DesignStory
            title="h2"
            type="type"
            props={{ className: "medium type-sp-s" }}>
            header 2
          </DesignStory>
          <DesignStory
            title="h3"
            type="type"
            props={{ className: "medium type-sp-s" }}>
            header 3
          </DesignStory>
          <DesignStory
            title="h4"
            type="type"
            props={{ className: "medium type-sp-s" }}>
            header 4
          </DesignStory>
          <DesignStory
            title="h5"
            type="type"
            props={{ className: "medium type-sp-xs" }}>
            header 5
          </DesignStory>
          <DesignStory
            title="h5"
            type="type"
            props={{ className: "shead medium type-sp-s" }}>
            small headline
          </DesignStory>
          <DesignStory
            title="h5"
            type="type"
            props={{ className: "xshead lmedium type-sp-s" }}>
            xsmall headline
          </DesignStory>
          <DesignStory
            title="h5"
            type="type"
            props={{ className: "xxshead medium type-sp-s" }}>
            xxsmall headline
          </DesignStory>
          <DesignStory
            title="h6"
            type="type"
            props={{ className: "medium type-sp-xs" }}>
            header 6
          </DesignStory>
          <DesignStory
            title="p"
            type="type"
            props={{ className: "p1 medium type-sp-s" }}>
            Paragraph 1
          </DesignStory>
          <DesignStory
            title="p"
            type="type"
            props={{ className: "p2 medium type-sp-s" }}>
            Paragraph 2
          </DesignStory>
          <DesignStory
            title="p"
            type="type"
            props={{ className: "p3 medium type-sp-s" }}>
            Paragraph 3
          </DesignStory>
          <DesignStory
            title="p"
            type="type"
            props={{ className: "p4 medium type-sp-s" }}>
            Paragraph 4
          </DesignStory>
          <DesignStory
            title="span"
            type="type"
            props={{ className: "microtext medium" }}>
            microtext
          </DesignStory>
          <DesignStory
            title="span"
            type="type"
            props={{ className: "labeltext medium" }}>
            labeltext
          </DesignStory>
          <h5>Small</h5>
          <DesignStory
            title="h1"
            type="type"
            props={{ className: "big small type-sp-xs" }}>
            big
          </DesignStory>
          <DesignStory
            title="h1"
            type="type"
            props={{ className: "small type-sp-xs" }}>
            header 1
          </DesignStory>
          <DesignStory
            title="h2"
            type="type"
            props={{ className: "small type-sp-xs" }}>
            header 2
          </DesignStory>
          <DesignStory
            title="h3"
            type="type"
            props={{ className: "small type-sp-xs" }}>
            header 3
          </DesignStory>
          <DesignStory
            title="h4"
            type="type"
            props={{ className: "small type-sp-xs" }}>
            header 4
          </DesignStory>
          <DesignStory
            title="h5"
            type="type"
            props={{ className: "small type-sp-xs" }}>
            header 5
          </DesignStory>
          <DesignStory
            title="h5"
            type="type"
            props={{ className: "shead small type-sp-s" }}>
            small headline
          </DesignStory>
          <DesignStory
            title="h5"
            type="type"
            props={{ className: "xshead small type-sp-s" }}>
            xsmall headline
          </DesignStory>
          <DesignStory
            title="h5"
            type="type"
            props={{ className: "xxshead small type-sp-s" }}>
            xxsmall headline
          </DesignStory>
          <DesignStory
            title="h6"
            type="type"
            props={{ className: "small type-sp-xs" }}>
            header 6
          </DesignStory>
          <DesignStory
            title="p"
            type="type"
            props={{ className: "p1 small type-sp-xs" }}>
            Paragraph 1
          </DesignStory>
          <DesignStory
            title="p"
            type="type"
            props={{ className: "p2 small type-sp-xs" }}>
            Paragraph 2
          </DesignStory>
          <DesignStory
            title="p"
            type="type"
            props={{ className: "p3 small type-sp-xs" }}>
            Paragraph 3
          </DesignStory>
          <DesignStory
            title="p"
            type="type"
            props={{ className: "p4 small type-sp-xs" }}>
            Paragraph 4
          </DesignStory>
          <DesignStory
            title="span"
            type="type"
            props={{ className: "microtext small" }}>
            microtext
          </DesignStory>
          <DesignStory
            title="span"
            type="type"
            props={{ className: "labeltext small" }}>
            labeltext
          </DesignStory>
        </div>
        <div className="design__story-section">
          <h5>Customizing Bold Type</h5>
          <p className="design__text p2">
            By default, all paragraph type displays as Inter Regular, but this
            can be customized by using a little sass magic:
          </p>
          <CodeBlock language="scss">
            @include typography-styles(&quot;p3&quot;, map-get($spacing, xs),
            &quot;semi&quot;);
          </CodeBlock>
          <p className="design__text p2">
            This displays Paragraph 3 text with Inter Semi Bold type.
          </p>
          <CodeBlock language="scss">
            @include typography-styles(&quot;p1&quot;, map-get($spacing, xs),
            &quot;bold&quot;);
          </CodeBlock>
          <p className="design__text p2">
            The above displays Paragraph 1 text with Inter Bold type.
          </p>
        </div>
      </div>
    </div>
  );
}

export default DesignTypography;
